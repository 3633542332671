<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center mb-3">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }} | {{ total }}
          </h3>
        </div>
      </div>
      <div class="table-responsive" v-if="transaction.type == 'order'">
        <base-table
          class="table align-items-center table-flush"
          :class="type === 'dark' ? 'table-dark' : ''"
          :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
          tbody-classes="list"
          :data="transactionDetails"
        >
          <template slot="columns">
            <th>Clave</th>
            <th>Nombre</th>
            <th>Unidades</th>
            <th>Precio Unitario</th>
            <th>Total Neto</th>
          </template>

          <template slot-scope="{ row }">
            <td>{{ row.articuloClave }}</td>
            <td>{{ row.articuloNombre }}</td>
            <td>{{ row.unidades }}</td>
            <td>$ {{ Number(row.precioUnitario).toFixed(2) }}</td>
            <td>$ {{ Number(row.precioUnitario * row.unidades).toFixed(2) }}</td>
          </template>
        </base-table>
      </div>

      <div class="table-responsive" v-if="transaction.type == 'payment'">
        <base-table
          class="table align-items-center table-flush"
          :class="type === 'dark' ? 'table-dark' : ''"
          :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
          tbody-classes="list"
          :data="transactionDetails"
        >
          <template slot="columns">
            <th>Folio Factura</th>
            <th>Importe Factura</th>
            <th>Saldo Factura</th>
            <th>Cargo</th>
          </template>

          <template slot-scope="{ row }"> 
            <td>{{ row.bill.folio }}</td>
            <td>$ {{ Number(row.bill.importeCargo).toFixed(2) }}</td>
            <td>$ {{ Number(row.bill.saldoCargo).toFixed(2) }}</td>
            <td>$ {{ Number(row.charge.importe).toFixed(2) }}</td>
          </template>
        </base-table>
      </div>

      <div class="table-responsive" v-if="transaction.type == 'return'">
        <base-table
          class="table align-items-center table-flush"
          :class="type === 'dark' ? 'table-dark' : ''"
          :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
          tbody-classes="list"
          :data="transactionDetails"
        >
          <template slot="columns">
            <th>Clave</th>
            <th>Nombre</th>
            <th>Precio Neto</th>
            <th>Unidades</th>
            <th>Unidades Devueltas</th>
            <th>Total Devuelto</th>
            <th>Total Neto</th>
          </template>

          <template slot-scope="{ row }">
            <td>{{ row.articuloClave }}</td>
            <td>{{ row.articuloNombre }}</td>
            <td>$ {{ row.precioNetoImp }}</td>
            <td>{{ row.unidades }}</td>
            <td>{{ row.unidadesDevueltas }}</td>
            <td>$ {{ Number(row.precioNetoImp * row.unidadesDevueltas).toFixed(2) }}</td>
            <td>$ {{ Number(row.precioNetoImp * row.unidades).toFixed(2) }}</td>
          </template>
        </base-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'transaction-details-table',
  props: {
    type: {
      type: String,
    },
    title: String,
    transaction: Object,
  },
  data() {
    return {
      transactionDetails: [],
      value: 1,
      perPage: 0,
      pageCount: 0,
      total: 0,
    }
  },
  mounted() {
    this.getTransactionDetailsInfo()
  },
  methods: {
    getTransactionDetailsInfo: function () {
      if (this.transaction.type == 'order' || this.transaction.type == 'return') {
        this.transactionDetails = this.transaction.body.lines
      } else if (this.transaction.type == 'payment') {
        this.transactionDetails = this.transaction.body.charges
      }
      this.total = this.transactionDetails.length
      this.value = 1
    },
  },
}
</script>
