<template>
  <div>
    <base-header type="gradient-blue" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <license-card
            title="Licencias adquiridas"
            icon="fas fa-key"
            class="mb-4 mb-xl-0"
          >
            <template slot="value">
              <span class="h1 font-weight-bold mb-0 text-purple">
                {{ licensesSummary.total }}
              </span>
            </template>
          </license-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <license-card
            title="Licencias en uso"
            icon="fas fa-mobile"
            class="mb-4 mb-xl-0"
          >
            <template slot="value">
              <span class="h1 font-weight-bold mb-0 text-green">
                {{ licensesSummary.used }}
              </span>
            </template>
          </license-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <license-card
            title="Licencias libres"
            icon="fas fa-id-card"
            color="text-green"
            class="mb-4 mb-xl-0"
          >
            <template slot="value">
              <span class="h1 font-weight-bold mb-0 text-orange">
                {{ licensesSummary.remaining }}
              </span>
            </template>
          </license-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <agents-table title="Lista de agentes"></agents-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AgentsTable from "./Tables/AgentsTable";
import httpMixin from "../mixins/httpMixin";
export default {
  name: "agents",
  components: {
    AgentsTable,
  },
  data() {
    return {
      licensesSummary: {},
    };
  },
  mounted() {
    this.fetchLicensesSummary();
  },
  methods: {
    fetchLicensesSummary: function () {
      this.httpGet("/licenses/summary")
        .then((res) => {
          this.licensesSummary = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mixins: [httpMixin],
};
</script>
<style></style>
