<template>
  <div>
    <base-header type="gradient-blue" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div id="my-account" class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">
                    {{
                      action == "new"
                        ? "Nuevo Agente"
                        : "Información del agente"
                    }}
                  </h3>
                </div>
                <div class="col-4 text-right">
                  <a href="#!" class="btn btn-sm btn-primary">Configuración</a>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">
                  Información de usuario
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-4">
                      <base-input
                        :required="true"
                        alternative=""
                        label="Nombre de usuario"
                        placeholder="Nombre de usuario"
                        input-classes="form-control-alternative"
                        v-model="model.name"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        :required="true"
                        type="email"
                        alternative=""
                        label="Correo Electrónico"
                        placeholder="ejemplo@ejemplo.com"
                        input-classes="form-control-alternative"
                        v-model="model.email"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        :required="true"
                        type="password"
                        alternative=""
                        label="Contraseña"
                        placeholder="Contraseña"
                        input-classes="form-control-alternative"
                        v-model="model.password"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Nombres"
                        placeholder="Nombres"
                        input-classes="form-control-alternative"
                        v-model="model.firstname"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Apellidos"
                        placeholder="Apellidos"
                        input-classes="form-control-alternative"
                        v-model="model.lastname"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">Configuración</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="form-control-label">
                        Elige la sucursal
                      </label>
                      <select
                        name="select"
                        class="form-control"
                        v-model="model.ms_sucursal_id"
                      >
                        <option
                          v-for="item in sucursales"
                          v-bind:key="item['ID']"
                          :value="item['ID']"
                        >
                          {{ item["NOMBRE"] }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4">
                      <label class="form-control-label">
                        Elige el almacén asignado
                      </label>
                      <select
                        name="select"
                        class="form-control"
                        v-model="model.ms_almacen_id"
                      >
                        <option
                          v-for="item in almacenes"
                          v-bind:key="item['ID']"
                          :value="item['ID']"
                        >
                          {{ item["NOMBRE"] }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4">
                      <label class="form-control-label">
                        Elige almacenes de consulta
                      </label>
                      
                      <select
                        multiple="true"
                        name="select"
                        class="form-control"
                        v-model="model.almacenes_consulta"
                        
                      >
                        <option
                          v-for="item in almacenes"
                          v-bind:key="item['ID']"
                          :value="item['ID']"
                        >
                          {{ item["NOMBRE"] }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">
                  Permisos de usuario:
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-4">
                      <base-checkbox
                        style="min-height: 2.3rem"
                        v-model="model.alta_clientes"
                      >
                        <span>Dar de alta clientes</span>
                      </base-checkbox>
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">
                  Información Vendedor
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-4">
                      <base-checkbox
                        style="min-height: 2.3rem"
                        v-model="model.vendedor"
                      >
                        <span>Es vendedor</span>
                      </base-checkbox>
                    </div>
                  </div>
                  <div class="row" v-if="model.vendedor">
                    <div class="col-md-4">
                      <label class="form-control-label">
                        Vendedor de Microsip
                      </label>
                      <select
                        name="select"
                        class="form-control"
                        v-model="model.ms_vendedor_id"
                      >
                        <option
                          v-for="item in vendedores"
                          v-bind:key="item['ID']"
                          :value="item['ID']"
                        >
                          {{ item["NOMBRE"] }}
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Serie Venta"
                        placeholder="Serie Venta"
                        input-classes="form-control-alternative"
                        v-model="model.venta_serie"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Folio Actual"
                        placeholder="Folio Actual"
                        input-classes="form-control-alternative"
                        v-model="model.venta_folio"
                      />
                    </div>                    
                    <div class="col-12">
                      <small>Esta opción permite al vendedor ofrecer ofertas o promociones que no están en tu sistema Microsip.</small>
                      <base-checkbox
                        style="min-height: 2.3rem"
                        v-model="model.ajustar_precio"
                      >
                        <span>El vendedor puede ajustar los precios de los artículos.</span>
                      </base-checkbox>
                    </div>
                    <div class="col-md-4 mt-3">
                      <label class="form-control-label">
                        Tipo de venta
                      </label>
                      <select
                        name="select"
                        class="form-control"
                        v-model="model.tipo_venta"
                      >
                        <option
                          v-for="item in sellTypes"
                          v-bind:key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div v-if="model.tipo_venta === 'caja'" class="col-12">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="form-control-label">
                            Cajero en Microsip
                          </label>
                          <select
                            name="select"
                            class="form-control"
                            v-model="model.ms_cajero_id"
                            @change="cajeroChange"
                          >
                            <option
                              v-for="item in cajeros"
                              v-bind:key="item['ID']"
                              :value="item['ID']"
                            >
                              {{ item["NOMBRE"] }}
                            </option>
                          </select>
                        </div>

                        <div class="col-md-4">
                          <label class="form-control-label">
                            Caja asignada
                          </label>
                          <select
                            name="select"
                            class="form-control"
                            v-model="model.ms_caja_id"
                          >
                            <option
                              v-for="item in cajas"
                              v-bind:key="item['ID']"
                              :value="item['ID']"
                            >
                              {{ item["NOMBRE"] }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Collector -->
                <h6 class="heading-small text-muted mb-4">
                  Información Cobrador
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-4">
                      <base-checkbox
                        style="min-height: 2.3rem"
                        v-model="model.cobrador"
                      >
                        <span>Es cobrador</span>
                      </base-checkbox>
                    </div>
                  </div>
                  <div class="row" v-if="model.cobrador">
                    <div class="col-md-4">
                      <label class="form-control-label">
                        Cobrador de Microsip
                      </label>
                      <select
                        name="select"
                        class="form-control"
                        v-model="model.ms_cobrador_id"
                      >
                        <option
                          v-for="item in cobradores"
                          v-bind:key="item['ID']"
                          :value="item['ID']"
                        >
                          {{ item["NOMBRE"] }}
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Serie Cobro"
                        placeholder="Serie Cobro"
                        input-classes="form-control-alternative"
                        v-model="model.cobro_serie"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Folio Actual"
                        placeholder="Folio Actual"
                        input-classes="form-control-alternative"
                        v-model="model.cobro_folio"
                      />
                    </div>
                  </div>
                  <div class="row" v-if="model.cobrador">
                    <div class="col-md-4">
                      <label class="form-control-label">
                        Lugar de Expedición
                      </label>
                      <select
                        name="select"
                        class="form-control"
                        v-model="model.ms_expedicion_id"
                      >
                        <option
                          v-for="item in lugaresexp"
                          v-bind:key="item['ID']"
                          :value="item['ID']"
                        >
                          {{ item["NOMBRE"] }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mt-4">
                      <base-checkbox
                        style="min-height: 2.3rem"
                        v-model="model.aplicar_cobro"
                      >
                        <span>Aplicar cobros en cobros de  cuentas por cobrar (solo punto de venta)</span>
                      </base-checkbox>
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Returns -->
                <h6 class="heading-small text-muted mb-4">
                  Información de devoluciones
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-4">
                      <base-checkbox
                        style="min-height: 2.3rem"
                        v-model="model.devolucion"
                      >
                        <span>Puede hacer devoluciones</span>
                      </base-checkbox>
                    </div>
                  </div>
                  <div class="row" v-if="model.devolucion">                    
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Serie Devolución"
                        placeholder="Serie Devolución"
                        input-classes="form-control-alternative"
                        v-model="model.devolucion_serie"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Folio Actual"
                        placeholder="Folio Actual"
                        input-classes="form-control-alternative"
                        v-model="model.devolucion_folio"
                      />
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-4">
                      <base-button v-on:click="saveAgent" type="primary"
                        >Guardar</base-button
                      >
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <base-alert
            ref="alert"
            :type="alert.type"
            :dismissible="true"
            :icon="alert.icon"
          >
            <template slot="text">{{ alert.message }}</template>
          </base-alert>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseAlert from "../components/BaseAlert.vue";
import httpMixin from "../mixins/httpMixin";
export default {
  components: { BaseAlert },
  name: "agent",
  data() {
    return {
      action: "",
      alert: {
        type: "default",
        message: "",
        icon: ""
      },
      agentId: 0,
      model: {
        id: 0,
        user_id: 0,
        ms_vendedor_id: 0,
        ms_cobrador_id: 0,
        ms_sucursal_id: 0,
        ms_almacen_id: 0,
        ms_expedicion_id: 0,
        ms_cajero_id: 0,
        ms_cajero_usuario: "",
        ms_caja_id: 0,
        venta_folio: 0,
        venta_serie: "",
        cobro_folio: 0,
        cobro_serie: "",
        devolucion_folio: 0,
        devolucion_serie: "",
        vendedor: false,
        cobrador: false,
        devolucion: false,
        ajustar_precio: false,
        tipo_venta: "",
        aplicar_cobro: false,
        expires_license: "",
        status: "",
        created_at: "",
        updated_at: "",
        name: "",
        email: "",
        password: "",
        firstname: "",
        lastname: ""
      },
      dataRequests: [],
      vendedores: [],
      cobradores: [],
      sucursales: [],
      lugaresexp: [],
      almacenes_consulta: [],
      cajeros: [],
      cajas: [],
      sellTypes: [
        {id: 'venta', name: 'Venta directa'}, 
        {id: 'caja', name: 'Venta de caja'}
      ]
    };
  },
  mounted() {
    this.initPusher()

    if (this.$route.params.id == "nuevo") {
      this.action = "new";
    } else {
      this.action = "edit";
      this.fetchAgentInfo();
    }
    
  },
  beforeDestroy() {
    this.$pusher.unsubscribe("private-" + this.$store.state.pusher.channel + '.' + this.$store.state.user.id);
  },
  methods: {
    initPusher: async function () {
      const channel = await this.$pusher.subscribe(
        "private-" + this.$store.state.pusher.channel + '.' + this.$store.state.user.id
      );
      await channel.bind("dataresponse", ({ data }) => {
        this.getDataResponse(data);
      });

      this.initDataRequets()
    },
    initDataRequets: function() {
       this.requestData('sucursales');
       this.requestData('almacenes');
       this.requestData('vendedores');
       this.requestData('cobradores');
       this.requestData('lugaresexp');
       this.requestData('cajeros');
       this.requestData('cajas');
    },
    fetchAgentInfo: async function() {
      this.httpGet("/agents/" + this.$route.params.id)
        .then(res => {
          this.model = {...res.data, almacenes_consulta: JSON.parse(res.data.almacenes_consulta)};
        })
        .catch(err => {
          console.log(err);
        });
    },
    requestData: async function(table) {
      const data = {
        table: table
      };
      await this.httpPost("/data/request", data)
        .then(res => {
          this.dataRequests.push({table: table, token: res.data.request_token})
        })
        .catch(err => {
          this.alert.type = "danger";
          this.alert.message = err.message;
          this.alert.icon = "ni ni-notification-70";
          this.$refs.alert.alertCreated();
        });
    },
    getDataResponse: async function(data) {
      this.httpGet("/data/response/" + data.token)
        .then(res => {
          let table = this.dataRequests.find(ele => ele.token == data.token);
          switch (table.table) {
            case 'vendedores':
                this.vendedores = res.data
              break;
            case 'cobradores':
                this.cobradores = res.data
              break;
            case 'sucursales':
                this.sucursales = res.data
              break;
            case 'almacenes':
                this.almacenes = res.data
              break;
            case 'lugaresexp':
                this.lugaresexp = res.data
              break;
            case 'cajeros':
                this.cajeros = res.data
              break;
            case 'cajas':
                this.cajas = res.data
              break;
            default:
              break;
          }
        })
        .catch(err => {
          this.alert.type = "danger";
          this.alert.message = err.message;
          this.alert.icon = "ni ni-notification-70";
          this.$refs.alert.alertCreated();
        });
    },
    saveAgent: function() {
      if (this.action == "new") {
        this.httpPost("/agents", this.model)
          .then(res => {
            this.alert.type = "success";
            this.alert.message = "Agente guardado correctamente";
            this.alert.icon = "ni ni-like-2";
            this.$refs.alert.alertCreated();
            setTimeout(() => {
              this.$router.push({ path: '/agentes' })
            }, 3000);
          })
          .catch(err => {
            this.alert.type = "danger";
            this.alert.message = err.message;
            this.alert.icon = "ni ni-notification-70";
            this.$refs.alert.alertCreated();
          });
      } else {
        this.httpPut("/agents", this.model)
          .then(res => {
            this.alert.type = "success";
            this.alert.message = "Agente guardado correctamente";
            this.alert.icon = "ni ni-like-2";
            this.$refs.alert.alertCreated();
          })
          .catch(err => {
            this.alert.type = "danger";
            this.alert.message = err.message;
            this.alert.icon = "ni ni-notification-70";
            this.$refs.alert.alertCreated();
          });
      }
    },
    cajeroChange: function () {
      const cajeroId = this.model.ms_cajero_id
      const cajero = this.cajeros.find(el => el['ID'] == cajeroId)
      this.model.ms_cajero_usuario = cajero['USUARIO']
    }
  },
  mixins: [httpMixin]
};
</script>
<style></style>
