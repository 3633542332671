<template>
  <div v-if="transaction.body">
    <base-header type="gradient-blue" class="pb-6 pb-8 pt-5 pt-md-5">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow mb-3">
            <div class="card-header border-0">
              <div class="row align-items-center mb-1">
                <div class="col-4">
                  <h3 class="h3">Folio: {{ transaction.body.folio }}</h3>
                </div>
                <div class="col-4">
                  <h3 class="h3">Agente: {{ transaction.agent_name }}</h3>
                </div>
                <div class="col-4">
                  <h3 class="h3">
                    {{ transaction.created_at | moment("DD/MM/YYYY hh:mm a") }}
                  </h3>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">
                  <h3 class="h3">Cliente: {{ transaction.client_name }}</h3>
                </div>
                <div class="col-4" v-if="transaction.type == 'return'">
                  <h3 class="h3">Total devuelto: <span class="text-warning"
                      >$ {{ getReturnedAmount(transaction) }}</span
                    ></h3>
                </div>
              </div>
              <div class="row align-items-center mb-1">
                <div class="col-4">
                  <h3 class="h3">
                    Tipo: {{ convertTypeToName(transaction.type) }}
                  </h3>
                </div>
                <div class="col-4">
                  <h3 class="h3">
                    {{ transaction.type == "return" ? "Total restante" : "Importe total"}}:
                    <span class="text-success"
                      >$ {{ getTotalAmount(transaction) }}</span
                    >
                  </h3>
                </div>
                <div class="col-4">
                  <h3 class="h3">
                    Estatus:
                    <span
                      :class="
                        'text-' + convertStatusToColor(transaction.status)
                      "
                      >{{ convertStatusToName(transaction.status) }}</span
                    >
                  </h3>
                </div>
              </div>
              <div class="row">
                <div v-if="transaction.server_response !== null" class="col-12">
                  <h3 class="h3">
                    Error:
                    <span class="font-weight-light">{{
                      transaction.server_response
                    }}</span>
                  </h3>
                </div>
              </div>
              <div
                v-if="transaction.status === 'E'"
                class="row align-items-center"
              >
                <div class="col-auto">
                  <button
                    @click="retryTransaction(transaction.id)"
                    class="btn btn-primary"
                  >
                    <i class="fas fa-redo-alt"></i>
                    Reintentar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-2">
      <div class="row">
        <div class="col">
          <transaction-details-table
            title="Detalles"
            :transaction="transaction"
          >
          </transaction-details-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import httpMixin from "../mixins/httpMixin";
import TransactionDetailsTable from "./Tables/TransactionDetailsTable";
export default {
  components: {
    TransactionDetailsTable,
  },
  data() {
    return {
      transaction: {},
    };
  },
  mounted() {
    this.fetchTransactionInfo();
  },
  methods: {
    fetchTransactionInfo: function () {
      this.httpGet(`/transactions/report/${this.$route.params.id}`)
        .then((res) => {
          this.transaction = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTotalAmount: function (transaction) {
      if (transaction.type == "order") {
        return Number(transaction.body.lines.reduce(
          (acc, line) => acc + line.precioUnitario * line.unidades,
          0
        )).toFixed(2);
      } else if (transaction.type == "payment") {
        return Number(transaction.body.charges.reduce(
          (acc, charge) => acc + charge.charge.importe,
          0
        )).toFixed(2);
      } else if (transaction.type == "return") {
        return Number(transaction.body.lines.reduce(
          (acc, line) => (acc + line.precioNetoImp * line.unidades) - (acc + line.precioNetoImp * line.unidadesDevueltas),
          0
        )).toFixed(2);
      }
    },
    getReturnedAmount: function (transaction) {
      return Number(transaction.body.lines.reduce(
        (acc, line) => acc + line.precioNetoImp * line.unidadesDevueltas,
        0
      )).toFixed(2);
    },
    retryTransaction(id) {
      this.httpPut("/transactions", {
        id: id,
        status: "R",
        response: null,
      })
        .then((res) => {
          if (res.status === "success") {
            this.status = "R";
            this.$router.push(`/transacciones`);
          }
        })
        .catch((err) => {
          this.serverResponse = err;
        });
    },
    convertTypeToName: function (type) {
      if (type == "order") {
        return "Venta";
      } else if (type == "payment") {
        return "Cobro";
      } else if (type == "return") {
        return "Devolución";
      }
    },
    convertStatusToName: function (status) {
      if (status == "P") {
        return "Pendiente";
      } else if (status == "S") {
        return "Guardado";
      } else if (status == "E") {
        return "Error";
      } else if (status == "R") {
        return "Reintentado";
      } else {
        return "Desconocido";
      }
    },
    convertStatusToColor: function (status) {
      if (status == "P") {
        return "warning";
      } else if (status == "S") {
        return "success";
      } else if (status == "E") {
        return "danger";
      } else if (status == "R") {
        return "info";
      } else {
        return "secondary";
      }
    },
  },
  mixins: [httpMixin],
};
</script>
